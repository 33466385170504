.sidebarContainer {
  position: relative;
}

.container {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 250px;
  background-color: #fff;
  overflow: hidden;
  transform: translateX(-310px);
  transition: transform 0.3s ease-in-out;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.containerOpen {
  transform: translateX(0);
}

.containerClosed {
  transform: translateX(-310px);
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
}

.toggleButton {
  animation: pulse 1s 5;
  position: fixed;
  top: 120px;
  z-index: 2;
  width: 30px;
  height: 30px;
  border-radius: 20%;
  background-color: #f8f8f8;
  border: 1px #2d2d2d solid;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
  color: #2d2d2d;
}

.toggleButton::before,
.toggleButton::after {
  content: '';
  position: absolute;
  width: 20px;
  height: 2px;
  transition: all 0.2s ease-in-out;
  pointer-events: none;
}

.toggleButtonOpen {
  left: 240px;
  opacity: 0.7;
}

.toggleButtonClosed {
  left: 5px;
  opacity: 1;
}

.dtLogo {
  margin-top: 20px;
  padding: 0 20px;
  display: flex;
  align-items: center;
}

.dtLogo img {
  height: 20px;
  margin-right: 10px;
}

.dtLogo h1 {
  font-size: 20px;
  font-weight: 600;
  color: #1d2c4c;
  margin: 0;
  padding: 0;
}

.menu {
  margin: 10px;
  list-style: none;
  padding-inline-start: 0;
  min-width: 200px;
}

.menuItem {
  margin: 10px;
  padding: 10px;
}

.menuItem:nth-child(2) {
  margin-bottom: 60px;
}

.menuItem:nth-child(3)::before {
  content: "PROGRAMMING AREAS";
  position: absolute;
  margin-top: -40px;
  color: #e4e4e4;
  font-weight: 600;
  line-height: 150%;
}

.menuItem:hover {
  background-color: #eff1f3;
  cursor: pointer;
}

.menuItem:has(> .itemSelected) {
  background-color: #eff1f3;
}

.menuLink {
  all: unset;
  color: #7f7f7f;
  display: flex;
  font-weight: 600;
  cursor: pointer;
}

.itemSelected {
  all: unset;
  color: #7f7f7f;
  display: flex;
  font-weight: 600;
}

.supportButton {
  color: #2d2d2d;
  font-weight: 600;
  cursor: pointer;
  margin: 10px;
  border: 1px solid #2d2d2d;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  background-color: #d9acd0;
}

.supportButton:hover {
  background-color: #f9aaff;
}

main {
  display: flex;
  flex-direction: column;
  font-weight: 400;
}

section {
  margin: 20px;
}

.openSection {
  margin: 5px;
}

.header {
  background-color: #fff;
  color: #2d2d2d;
}

.header h2 {
  margin: 20px 10px;
  text-justify: auto;
}

.reportContainer {
  height: calc((100vw - 60px) * 0.59);
  margin: 0 20px;
  border-radius: 5px;
  background-color: #fff;
}

.noMargin {
  height: calc((100vw - 60px) * 0.59);
  margin: 0;
  border-radius: 5px;
  background-color: #fff;
}

.contentMap {
  min-height: 400px;
  width: 100%;
  border: none;
}

.topMenu {
  background-color: #fff;
}

.topMenu > ul {
  list-style: none;
  display: flex;
  padding-inline-start: 0;
}

.activeLink {
  color: #2d2d2d;
  border-bottom: 2px solid #2d2d2d;
}

.activeLink button {
  color: #2d2d2d;
  opacity: 100%;
  font-weight: 500;
}

.topMenu li:hover {
  color: #2d2d2d;
  border-bottom: 2px solid #2d2d2d;
}

.topMenu li:hover button {
  color: #2d2d2d;
  opacity: 100%;
  cursor: pointer;
}

.topMenuButton {
  padding: 20px 20px 20px 40px;
  border: 0;
  background-color: #fff;
  opacity: 30%;
}

.summa {
  background: url(../icons/summa.svg) no-repeat 16px;
}

.conte {
  background: url(../icons/conte.svg) no-repeat 16px;
}

.activ {
  background: url(../icons/activ.svg) no-repeat 16px;
}

.monit {
  background: url(../icons/monit.svg) no-repeat 16px;
}

.closed {
  display: flex;
  justify-content: center;
}

.cssFix {
  white-space: pre-wrap;
}

.menu {
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  justify-content: center;
}

.menuItems {
  padding: 20px 20px 20px 20px;
  border: 0;
  background-color: #fff;
  opacity: 30%;
  font-weight: 700;
}

.menuItems:hover {
  opacity: 100%;
  color: #2d2d2d;
  border-bottom: 2px solid #2d2d2d;
}

.activeItem {
  color: #2d2d2d;
  border-bottom: 2px solid #2d2d2d;
  opacity: 100%;
}

.textContainer {
  background-color: #fff;
  padding: 30px;
  margin-top: 10px;
  border-radius: 5px;
}

.text {
  margin-bottom: 30px;
  padding: 10px;
}

.textContainer h2 {
  font-size: 1.25rem;
  font-weight: 700;
}

.par {
  line-height: 1.5rem;
  padding: 0.5rem;
  text-align: justify;
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');

* {
  font-family: 'Inter', sans-serif;
}

iframe {
  width: 100%;
  border: none;
  min-height: 400px;
}

.main {
  display: grid;
  background-color: #f8f8f8;
  transition: margin-left 0.3s ease-in-out;
  margin-left: 0;
  position: relative;
}

.open {
  grid-template-columns: 250px 1fr;
  transition: padding 0.3s ease-out;
}

.closed {
  grid-template-columns: 0 1fr;
  transition: padding 0.3s ease-out;
}

@media only screen and (max-width: 600px) {
  .main {
    grid-template-columns: 1fr;
    margin-left: 0;
  }
}
